import React from "react";

const CheckBox = ({ name, onChange, type, value, className }) => {
  return (
    <>
      <input type={type} name={name} value={value} onChange={onChange} />
      <span>
        <i className={className}></i>
      </span>
    </>
  );
};

export default CheckBox;
