import { createApi } from "@reduxjs/toolkit/query/react";
import  baseQueryWithReauth  from "../baseQuery";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    listUserRoom: builder.query({
      query: (values) => ({
        url: `/chat/user-room/list`,
        method: "GET",
        params: values,
      }),
    }),
    chatListing: builder.query({
        query: (data) => ({
          url: `/chat/${data.roomId}`,
          method: "GET",
        //   params: {
        //     sortBy: "createdAt:desc",
        //     limit: 10,
        //     page: values?.page,
        //   },
        }),
        // providesTags: [""],
      }),
  }),
});

export const {
  useLazyListUserRoomQuery,
  useLazyChatListingQuery
} = chatApi;
