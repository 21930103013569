import React, { useState } from "react";
import "@containers/searchcomponent/SearchPage.scss";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import { isValidUrl } from "@utils/validUrl";
import UserImg from "@assets/defaultUser.png";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { useSelector } from "react-redux";
import { showNotification } from "@utils/utilityFunctions";
import { Link } from "react-router-dom";
import  car  from "@assets/car.jpg";

const CrewPart = ({ stateCrew, setPage, page, loading }) => {
  // const resCrew = stateCrew?.Crews?.value?.docs;
  const resCrew=[
    
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      description:"Car is good",
      type: 2
    },
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      description:"Car is good",
      type: 2
    },
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      description:"Car is good",
      type: 2
    },
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      description:"Car is good",
      type: 2
    },
  ]
  const [loadingStates, setLoadingStates] = useState({});
  const [joinedCrews, setJoinedCrews] = useState([]);
  let _id, ExploreJoinCrew;
 
  const ShowAllData = () => {
    setPage(page + 1);
  };
  const handleJoinCrew = async (crewId, index) => {
    try {
      const body = {
        requestTo: _id,
      };
      setLoadingStates((prev) => ({ ...prev, [index]: true }));
      const res = await ExploreJoinCrew(crewId, body);
      if (res?.data?.code == 200) {
        setJoinedCrews((prev) => [...prev, crewId]);
      }
    } catch (error) {
      showNotification(error?.response?.data?.message, "error");
    } finally {
      setLoadingStates((prev) => ({ ...prev, [index]: false }));
    }
  };
  const filteredCrews = resCrew?.filter(
    (item) => !joinedCrews?.includes(item._id)
  );

  return (
    <div>
      <div className="view-all-heading" onClick={ShowAllData}>
        {!loading && (
          <>
            {/* <h2>{stateCrew?.Crews?.name}</h2> */}
             <h2>Crews in your area</h2>
            <Link to={`/explore/builds/task`}>
              See More
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 11.25L10 7.5L6.25 3.75"
                  stroke="#387AFF"
                  stroke-linecap="round"
                />
              </svg>
            </Link>
          </>
        )}
      </div>

      <div
        className="crew-list-content-search"
        style={{  overflowY: "scroll" }}
      >
        {loading ? (
          <div className="fadding-loader">
            <FadingLoader />
          </div>
        ) : (
          <>
            {filteredCrews?.map((item, index) => {
              // console.log('item22', item)
              return (
                <div className="list-block" key={item?._id}>
                  <Link
                    to={`/crew-post/${item?._id}`}
                    className="list-block"
                    style={{
                      borderBottom: "none",
                      color: "#fff",
                      padding: "unset",
                      marginBottom: "unset",
                    }}
                  >
                    <img
                      src={
                        isValidUrl(item?.bannerImage)
                          ? item?.bannerImage
                          // : UserImg
                          :car
                      }
                      alt={item?.name}
                      className="main-img"
                      style={{ borderRadius: "5px" }}
                    />
                    <div className="list-info">
                      <h4>
                        {item?.name}
                        {item?.privacy != 0 && (
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Private group badge">
                              <path
                                id="Path 7"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14 10.1053C14 12.3509 11.6667 14.3158 7 16C2.33333 14.3158 0 12.3509 0 10.1053C0 7.85965 0 5.05263 0 1.68421C3.13611 0.561404 5.46945 0 7 0C8.53055 0 10.8639 0.561404 14 1.68421C14 5.05263 14 7.85965 14 10.1053Z"
                                fill="#E56424"
                              />
                              <g id="lock">
                                <rect
                                  id="Rectangle 3"
                                  x="4"
                                  y="6.33105"
                                  width="6"
                                  height="4.66839"
                                  stroke="#F5F5F5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  id="Rectangle 2"
                                  d="M4.66602 5.33333C4.66602 4.04467 5.71068 3 6.99935 3V3C8.28801 3 9.33268 4.04467 9.33268 5.33333V6.33456H4.66602V5.33333Z"
                                  stroke="#F5F5F5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                            </g>
                          </svg>
                        )}
                      </h4>
                      <p>
                        {item?.description?.length > 20
                          ? `${
                              sliceStringAtWord(item?.description, 20) + "..."
                            }`
                          : `${item?.description}`}
                      </p>
                    </div>
                  </Link>
                  <button
                    className="default-btn"
                    style={{width:"max-content"}}
                    onClick={() => handleJoinCrew(item?._id, index)}
                  >
                    {loadingStates[index] ? "..." : "Join"}
                  </button>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default CrewPart;
