// import io from 'socket.io-client';

// Configurations for socket connection
// const socketIOConnOpt = {
//   'force new connection': true,
//   reconnection: true,
//   reconnectionDelay: 10000,
//   reconnectionDelayMax: 60000,
//   reconnectionAttempts: 'Infinity',
//   timeout: 10000,
//   transports: ['websocket'],
// };

// let betasocketConfig = {
//   withCredentials: true,
//   transports: ['websocket'],
// };
// let socketConfig = {
//   withCredentials: true,
//   jsonp: false,
// };

// // Remove the '/v1' from the REACT_APP_BASE_URL
// const baseURL = process.env.REACT_APP_BASE_URL.replace('/v1', '');
// console.log("BaseUrl",baseURL);
// // Create a socket connection using the modified base URL
// const socket = io(baseURL, socketConfig);
// console.log("BaseUrl",socket);
// export default socket;

import { io } from 'socket.io-client';

const baseURL = process.env.REACT_APP_BASE_URL.replace('/v1', '');

// const socket = io(baseURL);

 const socket = io(baseURL, {
    autoConnect: false
  });
  export default socket;