import React, { useState } from "react";
import "@containers/searchcomponent/SearchPage.scss";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import UserImg from "@assets/defaultUser.png";
import { isValidUrl } from "@utils/validUrl";
import { showNotification } from "@utils/utilityFunctions";
import { Link } from "react-router-dom";

const AccountParts = ({ stateAccount, loading }) => {
  const [loadingStates, setLoadingStates] = useState({});
  const [followAccount, setFollowAccount] = useState([]);
  let ExploreFollowUser;
  // const resAccount = stateAccount?.Accounts?.value?.docs;
  const resAccount = [{
    _id:"1",
    username:"John Doe",
    firstName:"John",
    profileImage:UserImg
  },
  {
    _id:"1",
    username:"John Doe",
    firstName:"John",
    profileImage:UserImg
  },
  {
    _id:"1",
    username:"John Doe",
    firstName:"John",
    profileImage:UserImg
  }
]
  const handleFollow = async (followId, index) => {
    setLoadingStates((prev) => ({ ...prev, [index]: true }));
    try {
      const data = {
        followToUserId: followId,
      };
      const res = await ExploreFollowUser(data);
      if (res?.data?.code == 200) {
        setFollowAccount((prev) => [...prev, followId]);
      }
    } catch (error) {
      showNotification(error?.response?.data?.message, "error");
    } finally {
      setLoadingStates((prev) => ({ ...prev, [index]: false }));
    }
  };
  const filteredAccount = resAccount?.filter(
    (item) => !followAccount?.includes(item?._id)
  );
  return (
    <div>
      <div className="view-all-heading">
        {!loading && (
          <>
            {/* <h2>{stateAccount?.Accounts?.name} </h2> */}
            <h2>Accounts</h2>
            <Link to={`/explore/builds/task`}>
              See More
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 11.25L10 7.5L6.25 3.75"
                  stroke="#387AFF"
                  stroke-linecap="round"
                />
              </svg>
            </Link>
          </>
        )}
      </div>

      <div
        className="crew-list-content-search"
        style={{  overflowY: "scroll" }}
      >
        {loading ? (
          <>
            <FadingLoader />
          </>
        ) : (
          <>
            {filteredAccount?.map((item, index) => {
              // console.log('item11', item)
              return (
                <div className="list-block user-list-block" key={index}>
                  <Link
                    to={`/profile/${item?._id}`}
                    className="list-block"
                    style={{
                      borderBottom: "none",
                      color: "#fff",
                      padding: "unset",
                      marginBottom: "unset",
                    }}
                  >
                    <img
                      src={
                        isValidUrl(item?.profileImage)
                          ? item?.profileImage
                          : UserImg
                      }
                      alt={item?.username}
                      style={{width:"40px",height:"40px"}}
                      className="main-img"
                    />
                    <div className="list-info">
                      <h4>{item?.username}</h4>
                      <p>{item?.firstName}</p>
                    </div>
                  </Link>
                  <button
                    className="default-btn"
                    style={{width:"max-content"}}
                    onClick={() => handleFollow(item?._id, index)}
                  >
                    {loadingStates[index] ? "..." : "Follow"}
                  </button>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default AccountParts;
