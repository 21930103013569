import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DefaultUserImg } from "@utils/defaultImages";
import { useSelector } from "react-redux";
import { isValidUrl } from "@utils/validUrl";
import Drawer from "./Drawer";
import "./SideBarMain.scss"; // Import your CSS file for additional styles

function SideBarMain({ isMobile, isTablet }) {
  /**
   * GET GLOBAL STATE
   */
  // {GLOBALLY GET USER LOGIN IN DATA}
  const userLoginToken = useSelector(
    (state) => state?.auth?.userAuthInfo?.access?.token
  );
  const userData = useSelector((state) => state?.auth?.loginUserData);
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const listSideBar = [
    {
      path: "/main-feed",
      title: "Home",
    },
    {
      path: "/explore/all",
      title: "Explore",
    },
    {
      path: "/crews/all", 
      title: "Crews",
    },
    // {
    //   path: "/messenger",
    //   title: "Messenger",
    // },
    // {
    //   path: "",
    //   title: "Events",
    // },
    // {
    //   path: "",
    //   title: "Notifications",
    // },
    // {
    //   path: "",
    //   title: "Settings",
    // },
    {
      path: "/profile",
      title: "Profile",
    },
    // {
    //   path: "",
    //   title: "More",
    // },
  ];
   // Scroll to top function
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className={isMobile && userLoginToken && "sideBarMain"}>
        {userLoginToken && (
          <ul
            className={"profile-links-side"}
            style={{ padding:"0 15px" }}
          >
            {listSideBar?.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  onClick={scrollToTop}
                  className={`svg-container ${
                    location.pathname === item.path ? "active" : ""
                  }`}
                  style={{ justifyContent: isTablet && "center" }}
                >
                  {!item.isImage ? (
                    <img
                      src={require("assets/sideBarImages/" +
                        item.title +
                        ".svg")}
                      alt={item.title}
                      height={20}
                      width={20}
                    />
                  ) : (
                    <img
                      src={item?.Image}
                      alt={item.title}
                      height={20}
                      width={20}
                    />
                  )}
                  {!isTablet && (
                    <span className="sidebarLinks">{item.title}</span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      {openDrawer && <Drawer closeDrawer={() => setOpenDrawer(false)} />}
    </>
  );
}

export default SideBarMain;
