import React, { useEffect, useState } from "react";
import BannerImg from "@assets/car.jpg";
import "./SearchPage.scss";
import SideBarMain from "@components/SideBarMain";
import { MultipleUsers } from "../Crew/components/MultipleUsers";
import { capitalizeAndAddSpace } from "@utils/capitalizeAddSpace";
import DefaultUserImg from "@assets/defaultUser.png";
import ExploreAccounts from "./ExploreAccounts";
import ExploreFilters from "./ExploreFilters";
import GaragePart from "@containers/ExplorePage/components/GaragePart";
import CrewPart from "@containers/ExplorePage/components/CrewPart";
import AccountParts from "@containers/ExplorePage/components/AccountParts";
import PostPage from "@containers/ExplorePage/components/PostPage";
import { useParams } from "react-router";
import StarRating from "@components/StarRating";
import { useMediaQuery } from "react-responsive";

const SearchResults = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({minWidth:700,  maxWidth: 992 });
  // console.log( isTablet);
  const [userinfo, setUserInfo] = useState({
    years: [],
  });
  let state = {};
  const { keyword } = useParams();
  const [search, setSearch] = useState(keyword)
  const [activeCategory, setActiveCategory] = useState("");
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  useEffect(() => {
    const toggleIcons = (event) => {
      const icon = event.currentTarget.querySelector("i");
      if (icon.classList.contains("fa-chevron-down")) {
        icon.classList.remove("fa-chevron-down");
        icon.classList.add("fa-chevron-up");
      } else {
        icon.classList.remove("fa-chevron-up");
        icon.classList.add("fa-chevron-down");
      }

      const allIcons = document.querySelectorAll(".acc-btn i");
      allIcons.forEach((otherIcon) => {
        if (otherIcon !== icon) {
          otherIcon.classList.remove("fa-chevron-up");
          otherIcon.classList.add("fa-chevron-down");
        }
      });
    };

    const accordionButtons = document.querySelectorAll(".acc-btn");
    accordionButtons.forEach((button) => {
      button.addEventListener("click", toggleIcons);
    });

    return () => {
      accordionButtons.forEach((button) => {
        button.removeEventListener("click", toggleIcons);
      });
    };
  }, []);

  const exploreData = [{}];
  return (
    <>
      <div
        // className="searchResults-container"
        style={{
          // activeCategory !== "Builds" ||
          gridColumn:
            activeCategory === "" || (activeCategory === "Accounts" && !(isMobile))  || isTablet 
              ? "2 / 4"
              : "",
          // height: "100vh"
        }}
      >
        {/* <SideBarMain /> */}
        <div>
          <div className="full-search">
            <div className="search-form">
              <div className="search-fld">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search for anything"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {
                  activeCategory !== "" &&
                  <>
                    <span className="active-tag">
                      {activeCategory}
                      <svg
                        onClick={() => setActiveCategory("")}
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M8.82869 8.82828L3.17184 3.17142"
                          stroke="white"
                          stroke-linecap="round"
                        />
                        <path
                          d="M8.82816 3.17142L3.17131 8.82828"
                          stroke="white"
                          stroke-linecap="round"
                        />
                      </svg>
                    </span>
                  </>
                }

                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M12.0094 12.5102L16.666 17.1667"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/* <div className="search-cate">
              <span className="active">Builds</span>
              <span>Crews</span>
              <span>Accounts</span>
              <span>Businesses</span>
              <span>Tags</span>
              <span>Posts</span>
              <span>HowTo</span>
              <span>Reviews</span>
            </div> */}
            <div className="search-cate">
              {[
                "Builds",
                "Crews",
                "Accounts",
              ].map((category) => (
                <span
                  key={category}
                  className={category === activeCategory ? "active" : ""}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </span>
              ))}
            </div>
            {activeCategory == "Builds" && (
              <>
                <div className="view-all-heading">
                  <h2>Builds</h2>
                </div>

                <div className="garage-row garage-row-two-clm" style={{ gap: "20px" }}>
                  {[1, 2, 3, 4, 5, 6].map(() => (
                    <div className="garage-block">
                      <img src={BannerImg} style={{ height: "220px" }} />
                      <div className="info">
                        <div className="stars">
                          <StarRating
                            rating={4}
                            isShow={false}
                            isRedirect={false}
                          />
                        </div>

                        <div className="model">2020 Ford</div>
                        <div className="name">Shelby GT500  <span className="current" style={{
                          backgroundColor : "#387aff",width:"fit-content",
                        lineHeight:"inherit",padding: "4px 6px",fontSize:"10px"
                        }}>Current</span></div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {activeCategory == "Crews" && (
              <>
                <div className="view-all-heading">
                  <h2>{capitalizeAndAddSpace("Crews")} </h2>
                </div>{" "}
                <div
                  className="crew-garage-row"
                  style={{ gridTemplateColumns: "1fr 1fr" }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
                    <>
                      <div
                        className="garage-block"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={BannerImg}
                          // alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
                          style={{ height: "220px" }}
                        />
                        <div className="info">
                          <div className="model" style={{ fontSize: "20px", fontWeight: "bold" }}>Crew Name</div>
                          <div className="name">MeetUp</div>
                        </div>
                        <div
                          className="group-2"
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            className="text-wrapper-3"
                            style={{ fontSize: isMobile? "8px" :"14px", fontWeight: 400 }}
                          >
                            1.2k members
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <MultipleUsers
                              avatar={DefaultUserImg}
                              avatar1={DefaultUserImg}
                              avatar2={DefaultUserImg}
                              avatar3={DefaultUserImg}
                              avatar4={DefaultUserImg}
                              className="multiple-users-instance"
                              img={DefaultUserImg}
                              property1="users-sm"
                            />
                          </div>

                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
            {activeCategory == "Accounts" && <ExploreAccounts />}
            {activeCategory === "" &&
              <>
                {/* Garage Row */}
                <GaragePart
                  BannerImg={BannerImg}
                  statebuild={state}
                  loading={loading}
                />
                {/* Crew list heading  */}
                <CrewPart
                  BannerImg={BannerImg}
                  stateCrew={state}
                  setPage={setPage}
                  page={page}
                  loading={loading}
                />
                {/* Accounts details */}
                <AccountParts
                  BannerImg={BannerImg}
                  stateAccount={state}
                  loading={loading}
                />
                {/* post details */}
                <PostPage statePost={state} />
              </>
            }
          </div>
        </div>

      </div>
      {activeCategory !== "" && activeCategory !== "Accounts" && <ExploreFilters
        activeCategory={activeCategory}
        userinfo={userinfo}
        setUserInfo={setUserInfo}
      />}

    </>
  );
};

export default SearchResults;
