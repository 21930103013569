import React, { useCallback, useEffect, useState } from "react";
import { capitalizeAndAddSpace } from "@utils/capitalizeAddSpace";
import { ReactComponent as ArrowRight } from "@assets/arrow-down.svg";
import { useParams } from "react-router";
import PostsHeaderLoader from "@components/Skeleton/PostsHeaderLoader";
import NotFound from "@components/NotFound";
import TextImg from "@assets/text.png";
import { Link } from "react-router-dom";
import { dateTimeFromNow } from "@utils/DateFormatter";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { useLazyGetSelectedFilterCategoryQuery } from "@redux/services/crewService/allCrewService";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";

const CrewFilter = ({ activeIndex, selectCategoryId, page, setPage, isMobile, isTablet }) => {
  const [selectFilterResult, setSelectFilterResult] = useState([]);
  const { crewId } = useParams();
  /**
   * API CALLS
   */
  const [getSelectedFilterCategory, { isLoading }] =
    useLazyGetSelectedFilterCategoryQuery();
  //
  /**
   * FUNCTIONS
   */

  const discussionCategoryList = useCallback(async () => {
    const body = {
      crewId: crewId,
      categoryId: selectCategoryId?._id,
      page: page?.nextPage,
    };
    try {
      const resSelectFilter = await getSelectedFilterCategory(body).unwrap();
      setSelectFilterResult(resSelectFilter?.data?.docs);
      setPage(resSelectFilter?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  }, [selectCategoryId?._id, page]);

  /**
   * USEEFEECT
   */
  useEffect(() => {
    discussionCategoryList();
  }, [activeIndex, selectCategoryId?._id]);
  return (
    <React.Fragment>
      <div className="posts-header">
        {isLoading ? (
          isLoading && (isMobile || isTablet) ? (
            <SpinnerLoader />
          ) : (
            Array.from({ length: 2 }).map((_, index) => (
              <PostsHeaderLoader key={index} />
            ))
          )
        ) : selectFilterResult?.length > 0 ? (
          <>
            <div className="all-post">
              <h1>{capitalizeAndAddSpace(selectCategoryId?.title)}</h1>
            </div>

            {selectFilterResult?.map((filter, index) => {
              return (
                <>
                  <Link
                    className="wrape-posts"
                    to={`/post/${filter?._id}`}
                    state={{
                      filter: true,
                      id: crewId,
                    }}
                  >
                    {" "}
                    <div className="technical-posts">
                      <div className="main-data">
                        <div className="wrap-innerData">
                          <div
                            className="post-img"
                            style={{
                              opacity:
                                filter?.media[0]?.path?.includes("mp4") &&
                                "0.6",
                            }}
                          >
                            {filter?.media[0]?.path?.includes("mp4") ? (
                              <video
                                src={filter?.media[0]?.path}
                                alt={filter?.title}
                              />
                            ) : (
                              <img
                                src={
                                  filter?.media?.length > 0
                                    ? filter?.media[0]?.path
                                    : TextImg
                                }
                                alt={filter?.title}
                                width="50px"
                                height="50px"
                              />
                            )}
                            {filter?.media[0]?.path?.includes("mp4") && (
                              <div className="video-overlay">
                                <span className="video-icon">▶️</span>
                              </div>
                            )}
                          </div>
                          <div className="wrap-innerposts">
                            <div className="username">
                              <div className="posted">
                                <h6>
                                  {filter?.title?.length > 0 ||
                                  filter?.description?.length > 0
                                    ? capitalizeAndAddSpace(
                                        filter?.title || filter?.description
                                      )
                                    : "Media Post"}
                                </h6>
                              </div>
                              <div className="width"></div>
                              <div className="posted-by">
                                <p>
                                  Posted by{" "}
                                  <strong>
                                    {filter?.user?.username?.length > 15
                                      ? `${
                                          sliceStringAtWord(
                                            filter?.user?.username,
                                            15
                                          ) + "..."
                                        }`
                                      : filter?.user?.username}
                                  </strong>
                                </p>
                              </div>
                              <div className="width"></div>
                              <div className="posted-by">
                                <p>{dateTimeFromNow(filter?.createdAt)}</p>
                              </div>
                            </div>
                            <div className="title-inner">
                              <h3>
                                {filter?.title
                                  ? filter?.title
                                  : filter?.description}
                              </h3>
                            </div>
                            {filter?.commentsCount > 0 && (
                              <div className="replies-count">
                                <p>{filter?.commentsCount} comments</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="arrow">
                          <ArrowRight />
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              );
            })}
          </>
        ) : (
          <NotFound message="Post" />
        )}
      </div>
    </React.Fragment>
  );
};

export default CrewFilter;
