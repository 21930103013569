import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "./TabSwitch.scss";
import { Link, useParams } from "react-router-dom";
import { useLazyGetBuildFollowVehiclesQuery } from "@redux/services/userProfile/UserProfileService";
import { isValidUrl } from "@utils/validUrl";
import { useSelector } from "react-redux";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { toast } from "react-toastify";
import NotFound from "@components/NotFound";
import { useDebounce } from "use-debounce";
import {
  DefaultUserPng,
  SearchIcon,
  ChevronRight,
  SeeData,
} from "@utils/defaultImages";

const FollowedVehicles = () => {
  /**
   * GLOBALLY STATES
   */
  const selfUser = useSelector((state) => state?.auth?.loginUserData);
  const { userId } = useParams();
  /**
   * USESTATES
   */
  const [searchResult, setSearchResult] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isApiComplete, setIsApiComplete] = useState(false);

  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(inputSearch, 1000);

  /**
   * SEARCH API
   */
  const [listBuildFollowed, { data: { data } = [], isLoading, isFetching }] =
    useLazyGetBuildFollowVehiclesQuery();

  /**
   * FUNCTIONS CALL
   */

  const showFollowersList = async (searchTerm = "") => {
    const body = {
      page: page,
      userId: userId,
      search: searchTerm,
    };
    try {
      const response = await listBuildFollowed(body).unwrap();
      setSearchResult((prevItems) =>
        page === 1
          ? response?.data?.docs
          : [...prevItems, ...response?.data?.docs]
      );
      setIsApiComplete(true);
    } catch (error) {
      toast.error(error?.data?.message);
      setIsApiComplete(true);
    }
  };

  const handleChange = (e) => {
    setInputSearch(e.target.value);
  };

  /**
   * USEEFFECT
   */
  useEffect(() => {
    setIsApiComplete(false);
    showFollowersList(debouncedSearch);
  }, [debouncedSearch, page]);

  return (
    <>
      {/* Search bar */}
      <div className="full-search">
        <div className="search-form">
          <div className="search-fld">
            <SearchIcon />
            <input
              value={inputSearch}
              type="search"
              className="form-control"
              placeholder="Search people"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="crew-list-content">

        {isLoading ? (
          <div className="list-block position-relative" >
            <div className="container-fluid p-0">
              <FadingLoader />
            </div>
          </div>
        ) : (
          <>
            {isApiComplete && searchResult?.length === 0 ? (
              <NotFound message="Followed Vehicles" />
            ) : (
              searchResult.map((crew) => (
                <div
                  className="d-flex justify-content-between align-items-center"
                  key={crew?._id}
                >
                  <div className="list-block-foll">
                    <Link to={`/profile/${crew?._id}`}>
                      <img
                        src={
                          isValidUrl(crew?.profileImage)
                            ? crew?.profileImage
                            : isValidUrl(crew?.bannerImage)
                              ? crew?.bannerImage
                              : DefaultUserPng
                        }
                        alt={crew?.name}
                        className="main-img"
                      />
                    </Link>
                    <Link
                      to={
                        selfUser?._id === crew?._id
                          ? `/profile`
                          : `/profile/${crew?._id}`
                      }
                      className="profile-link"
                    >
                      <div className="list-info">
                        <h4>{crew?.name.length>25 ? `${sliceStringAtWord(crew?.name, 25)}...`:crew?.name}</h4>
                        {/* <p style={{ whiteSpace: "nowrap" }}>{crew?.name}</p> */}
                      </div>
                    </Link>
                  </div>
                  <ChevronRight />
                </div>
              ))
            )}
            {isApiComplete && data?.hasNextPage && (
              <div
                className="text-center py-3"
                onClick={() => setPage(data?.nextPage)}
              >
                <SeeData role="button" />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FollowedVehicles;
