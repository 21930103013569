import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import MainLayout from "@components/layouts/MainLayout";
import Login from "@containers/Auth/LogIn";
import UserInfo from "@containers/Auth/SignUp/userInfo";
import VerifyOtp from "@containers/Auth/SignUp/VerifyOtp";
import GeneralInfo from "@containers/Auth/SignUp/GeneralInfo";
import Categories from "@containers/Auth/SignUp/Categories";
import PopularAccounts from "@containers/Auth/SignUp/PopularAccounts";
import HomeBanner from "@containers/HomePage/HomeBanner";
import CarDetail from "@containers/BuildProfile/CarDetail";
import ComponentPages from "@containers/ComponentPage/ComponentPage";
import HowToInstall from "@containers/HowToInstall/HowToInstall";
import GeneralPost from "@containers/GeneralPost/GeneralPost";
import ExplorePage from "@containers/ExplorePage/ExplorePage";
import Feed from "@containers/MainFeed/Feed";
import SeeAll from "@components/SeeAll";
import Review from "@containers/Review/Review";
import UserProfile from "@containers/UserProfile/UserProfile";
import SearchResults from "@containers/searchcomponent/SearchResults";
import FeedBack from "@containers/FeedBack/FeedBack";
import CrewAllData from "@containers/Crew/CrewFeed";
import CrewDetails from "@containers/Crew/CrewDetails";
import CrewEventDetail from "@containers/Crew/CrewPostsPage/CrewEventDetail";
import CrewPost from "@containers/Crew/CrewPostsPage/CrewPost";
import ComponentPosts from "@containers/ComponentPage/ComponentPosts";
import UserProfileId from "@containers/UserProfileById/UserProfileId";
import YourProfile from "@containers/Auth/SignUp/YourProfile";
import Landing from "@containers/LandingPage/Landing";
import StoriesView from "@containers/MainFeed/storiesView/StoriesView";
import SpotlightView from "@containers/MainFeed/spotlightView/SpotlightView";
import Messenger from "@containers/Messenger/Messenger";
import ChatBox from "@containers/Messenger/ChatBox";
import WaitingScreen from "@containers/Auth/SignUp/WaitingScreen";
import WaitingComplete from "@containers/Auth/SignUp/WaitingComplete";
import TermsCondition from "@containers/TermsCondition";
import PrivacyPolicy from "@containers/PrivacyPolicies";
import ContactUs from "@containers/ContactUs";
const routes = (user) => [
  {
    path: "/login",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <Login />
      </AuthRoutes>
    ),
  },
  {
    path: "/user-info",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <UserInfo />
      </AuthRoutes>
    ),
  },
  {
    path: "/verify-otp",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <VerifyOtp />
      </AuthRoutes>
    ),
  },
  {
    path: "/waiting-list/:id",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <WaitingScreen />
      </AuthRoutes>
    ),
  },
  {
    path: "/general-info",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <GeneralInfo />
      </AuthRoutes>
    ),
  },
  {
    path: "/categories",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <Categories />
      </AuthRoutes>
    ),
  },
  {
    path: "/popular-accounts",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <PopularAccounts />
      </AuthRoutes>
    ),
  },
  {
    path: "/user-profile",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <YourProfile />
      </AuthRoutes>
    ),
  },
  {
    path: "/home-banner",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <HomeBanner />
      </AuthRoutes>
    ),
  },
  {
    path: "/",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <Landing />
      </AuthRoutes>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <TermsCondition/>
      </AuthRoutes>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <PrivacyPolicy/>
      </AuthRoutes>
    ),
  },
  {
    path: "/contact-us",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <ContactUs/>
      </AuthRoutes>
    ),
  },
  {
    path: "/congratulations-view",
    element: (
      <AuthRoutes isLoggedIn={user?.access}>
        <WaitingComplete />
      </AuthRoutes>
    ),
  },
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      {
        path: "/main-feed",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <Feed />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <UserProfile />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/stories-list",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <StoriesView />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/spotlight-list",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <SpotlightView />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/profile/:Id",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <UserProfileId />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/:username/garage/:make/:model/:year/:Id",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <CarDetail />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/crews/all",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <CrewAllData />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/crews/list",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <CrewDetails />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/how-to-install/:componentStatId",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <HowToInstall />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/review/:reviewId",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <Review />
          </ProtectedRoutes>
        ),
      },
      // {
      //   path: "/profile/:userId/all",
      //   element: (
      //     <ProtectedRoutes isLoggedIn={user?.access}>
      //       <SeeAll />
      //     </ProtectedRoutes>
      //   ),
      // },
      {
        path: "/component-page/:componentId",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <ComponentPages />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/get-all-mods/:componentId",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <ComponentPosts />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/crew-post/:crewId",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <CrewPost />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/crew-event/detail/:postId",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <CrewEventDetail />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/explore/all",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <ExplorePage />
          </ProtectedRoutes>
        ),
      },
      // {
      //   path: "/serachResults/:keyword",
      //   element: (
      //     <ProtectedRoutes isLoggedIn={user?.access}>
      //       {/* <ExploreSearchPage /> */}
      //       <SearchResults />
      //     </ProtectedRoutes>
      //   ),
      // },
      // {
      //   path: "/messenger",
      //   element: (
      //     <ProtectedRoutes isLoggedIn={user?.access}>
      //       <Messenger />
      //     </ProtectedRoutes>
      //   ),
      // },
      // {
      //   path: "/view-chat/:id",
      //   element: (
      //     <ProtectedRoutes isLoggedIn={user?.access}>
      //       <ChatBox />
      //     </ProtectedRoutes>
      //   ),
      // },
      {
        path: "/search",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <SearchResults />
          </ProtectedRoutes>
        ),
      },
      {
        path: "/feedback",
        element: <FeedBack />, // Temporarily without protection
      },
      {
        path: "/explore/builds/task",
        element: (
          <ProtectedRoutes isLoggedIn={user?.access}>
            <SearchResults />
          </ProtectedRoutes>
        ),
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
      {
        path: "/post/:postId",
        element: <GeneralPost />,
      },
    ],
  },
];

export default routes;
