import React from "react";
import "./GaragePart.scss";
import { isValidUrl } from "@utils/validUrl";
import BuildSkeleton from "@components/Skeleton/BuildSkeleton";
import StarRating from "@components/StarRating";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { Link } from "react-router-dom";
import car from "@assets/car.jpg";

const GaragePart = ({ statebuild, loading }) => {
  // console.log("statebuild", statebuild);
  // const res = statebuild?.recommendedBuilds?.value?.docs;
  const res = [
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      make: "2021",
      modelyear: "2001",
      rating: "4",
      type: 2,
    },
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      make: "2021",
      modelyear: "2001",
      rating: "3",
      type: 2,
    },
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      make: "2021",
      modelyear: "2001",
      rating: "1",
      type: 1,
    },
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      make: "2021",
      rating: "0",
      modelyear: "2001",
      type: 1,
    },
    {
      _id: "1",
      name: "car",
      bannerImage: car,
      make: "2021",
      modelyear: "2001",
      rating: "4",
      type: 1,
    },
  ];
  return (
    <>
      <div className="view-all-heading-build">
        <h2>Builds </h2>
        <Link to={`/explore/builds/task`}>
          See More
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.25 11.25L10 7.5L6.25 3.75"
              stroke="#387AFF"
              stroke-linecap="round"
            />
          </svg>
        </Link>
      </div>{" "}
      <div className="garage-row-build">
        {res?.map((item) => {
          return (
            <div className="garage-block" key={item?._id}>
              <img
                src={
                  isValidUrl(item?.bannerImage)
                    ? item?.bannerImage
                    : item?.bannerImage
                }
                alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
              />
              <div className="info">
                <StarRating
                  rating={item?.rating}
                  isShow={false}
                  isRedirect={false}
                />
                <div>
                  <span
                    className={
                      item.type === 1 ? "current" : "previous"
                    }
                  >
                    {item?.type === 1 ? "Current" : "Previous"}
                  </span>
                  <div className="model">
                    {item?.year?.value} {item?.make}
                  </div>
                  <div className="name">
                    {sliceStringAtWord(item?.name, 10)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default GaragePart;
