import React, { useEffect, useRef, useState } from "react";
import SiteLogo from "@assets/site-logo.svg";
import "./Signup.scss";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from "@components/Forms/Formik/Form";
import RenderError from "@components/Error/RenderError";
import CheckPassword from "@components/CheckPassword";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import usePrompt from "@utils/hooks/useUnsavedChanges";
import { useDebouncedCallback } from "use-debounce";
import {
  useLazyUserCheckPhoneQuery,
  useUserInfoEmailMutation,
  useUserInfoUserNameMutation,
} from "@redux/services/auth/signUpService";
import { toast } from "react-toastify";
import { setUserInfo } from "@redux/slices/authSlice/signUpSlice";

const SignUpSchema1 = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Wrong email format")
    .required("Provide a email."),
  username: Yup.string()
    .matches(
      /^(?!.*\s)[\S]{6,30}$/,
      "Username must be 6 or less than 30 characters without spaces"
    )
    .required("Provide a username."),
  password: Yup.string()
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least 1 special character"
    )
    .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .matches(/[0-9]/, "Password must contain at least 1 number")
    .min(8, "Password must be at least 8+ characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  phoneNumber: Yup.string()
    .matches(
      /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
      "Invalid Input"
    )
    .min(10, "Phone number length is too short. It should be at least 10")
    .required("Please enter your phone number"),
});
export default function App() {
  const dispatch = useDispatch();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [userInfoEmail] = useUserInfoEmailMutation();
  const [userInfoUserName] = useUserInfoUserNameMutation();
  const [triggerCheckPhone, { isLoading, isFetching }] =
    useLazyUserCheckPhoneQuery();
  const [responseErrorMsg, setResponseErrorMsg] = useState({
    email: "",
    userName: "",
    numberError: "",
  });

  const formik = useFormik(
    {
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
      },
      validationSchema: SignUpSchema1,
    },
    []
  );
  const { errors, touched, values, handleBlur, isValid, dirty, setFieldError } = formik;

  const { setDirty, setPristine, Prompt } = usePrompt("ok");
  const navigate = useNavigate();
  // console.log('response----111', responseErrorMsg)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = { ...values };
    try {
      const response = await triggerCheckPhone(values?.phoneNumber).unwrap();
       if (response?.code === 200) {
        setResponseErrorMsg((prevState) => ({
              ...prevState,
              numberError: "",
            }));
        navigate("/verify-otp");
      }
      // console.log("error45", response);

      dispatch(setUserInfo(user));
      // if (response?.data?.code !== 200) {
      //   setResponseErrorMsg((prevState) => ({
      //     ...prevState,
      //     numberError: response?.error?.data?.message || "An error occurred",
      //   }));
      // } else {
      //   setResponseErrorMsg((prevState) => ({
      //     ...prevState,
      //     numberError: "",
      //   }));
      //   navigate("/verify-otp");
      // }
      // if (response?.code === 200) {
      //   navigate("/verify-otp");
      // }
    } catch (error) {
      setFieldError(error?.data?.message)
      setResponseErrorMsg((prevState) => ({
            ...prevState,
            numberError: error?.data?.message || "An error occurred",
          }));
    }
  };
  /**
   * ADD DEBOUNCE FUNCTIONALITY
   */
  const handleChangeEmail = async (email) => {
    const body = { email };
    try {
      const emailResponse = await userInfoEmail(body);
      if (emailResponse?.data?.code !== 200) {
        setResponseErrorMsg((prevState) => ({
          ...prevState,
          email: emailResponse?.error?.data?.message || "An error occurred",
        }));
      } else {
        setResponseErrorMsg((prevState) => ({
          ...prevState,
          email: "",
        }));
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleChangeUsername = async (username) => {
    const body = { username };
    try {
      const userNameResponse = await userInfoUserName(body);
      if (userNameResponse?.data?.code !== 200) {
        setResponseErrorMsg((prevState) => ({
          ...prevState,
          userName:
            userNameResponse?.error?.data?.message || "An error occurred",
        }));
      } else {
        setResponseErrorMsg((prevState) => ({
          ...prevState,
          userName: "",
        }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const debouncedHandleChangeEmail = useDebouncedCallback((email) => {
    handleChangeEmail(email);
  }, 1000);
  const debouncedHandleChangeUsername = useDebouncedCallback((username) => {
    handleChangeUsername(username);
  }, 1000);
  const handleDropDown = () => {
    setCountryShow(!countryShow);
  };
  const phoneInputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        phoneInputRef.current &&
        !phoneInputRef.current.contains(event.target)
      ) {
        setCountryShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      {Prompt}
      <div className="signup-container">
        <div className="signup-wrapper">
          <Form
            onSubmit={handleSubmit}
            formik={formik}
            submitText="Next"
            className="next-btn"
            disableSubmit={!isValid || !dirty}
            isDisableBtn={isLoading || isFetching}
            Btntype={false}
          >
            <img src={SiteLogo} alt="img78" className="logo" />
            <div className="lets-go">Let’s get started.</div>
            <div className="form-group">
              <label>What’s your name?</label>
              <div className="two-form">
                <input
                  type="text"
                  name="firstName"
                  className="form-control"
                  placeholder="First name"
                  value={values?.firstName}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />

                <input
                  type="text"
                  name="lastName"
                  className="form-control"
                  placeholder="Last name"
                  value={values?.lastName}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </div>
              <div className="form-group">
                <div className="two-form">
                  {touched?.firstName && (
                    <RenderError
                      message={touched?.firstName && errors?.firstName}
                    />
                  )}
                  {touched?.lastName && (
                    <RenderError
                      message={touched?.lastName && errors?.lastName}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>What’s your email?</label>
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email Address"
                value={formik.values.email}
                onChange={(e) => {
                  formik.handleChange(e);
                  debouncedHandleChangeEmail(e.target.value);
                }}
                onBlur={formik.handleBlur}
              />

              <RenderError
                message={
                  (formik.touched?.email && formik.errors?.email) ||
                  responseErrorMsg?.email
                }
              />
            </div>
            <div className="form-group">
              <label>Create a username.</label>
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="Username"
                value={formik.values.username}
                onChange={(e) => {
                  formik.handleChange(e);
                  debouncedHandleChangeUsername(e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              <RenderError
                message={
                  (formik.touched?.username && formik.errors?.username) ||
                  responseErrorMsg?.userName
                }
              />
            </div>
            {/* <div className="space10"></div> */}
            <div className="form-group">
              <label>Create a password.</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  value={values?.password}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  onBlur={handleBlur}
                />
                {values?.password?.length > 0 && (
                  <div
                    className="eye-icon-confirm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={`fas ${
                        showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                    ></i>
                  </div>
                )}{" "}
              </div>
              {touched?.password && (
                <RenderError message={touched?.password && errors?.password} />
              )}
            </div>
            <div className="form-group">
              <div className="input-group">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={values?.confirmPassword}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDirty();
                  }}
                  onBlur={handleBlur}
                />
                {values?.confirmPassword?.length > 0 && (
                  <div
                    className="eye-icon-confirm"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <i
                      className={`fas ${
                        showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                    ></i>
                  </div>
                )}
              </div>
              {touched?.confirmPassword && (
                <RenderError
                  message={touched?.confirmPassword && errors?.confirmPassword}
                />
              )}
            </div>
            <CheckPassword password={values?.password} />
            <div className="space10"></div>
            <div className="form-group">
              <label>What’s your phone number?</label>
              <div ref={phoneInputRef}>
                <PhoneInput
                  key={countryShow ? "open" : "closed"}
                  // type="text"
                  country={"us"}
                  placeholder="+1 000-000-0000"
                  name="phoneNumber"
                  value={values?.phoneNumber}
                  onChange={(value) => {
                    // formik.handleChange(e);
                    formik.setFieldValue("phoneNumber", value);
                    //   dispatch(resetCheckphone());
                    setDirty();
                  }}
                  onBlur={handleBlur}
                  inputClass="phone_input"
                  dropdownStyle={{ top: 0 }}
                  showDropdown={countryShow}
                />
              </div>

              <div className="country-code pt-1">
                <span onClick={handleDropDown}>Change country code</span>
              </div>
              {/* {touched?.phoneNumber && ( */}
                <RenderError
                  message={(touched?.phoneNumber && errors?.phoneNumber) || responseErrorMsg?.numberError}
                />
              {/* )} */}
              {/* <div className="message code">Change country code</div> */}
            </div>
            <div className="verification-code pb-1 pt-3">
              A verification code will be sent to this phone number
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
