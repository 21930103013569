import React, { useState } from "react";
import "./CrewEventDetail.scss";
import { useParams } from "react-router";
import {
  StartAndEndDate,
  formatTimeRange,
  getOnlyDate,
  getOnlyMonth,
} from "@utils/DateFormatter";
import { MapImg } from "utils/defaultImages";

import GoogleMapComponent from "@components/Googlemap/GoogleMapComponent";
import { isValidUrl } from "@utils/validUrl";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import { useSelector } from "react-redux";
import CrewEventDetailSkeleton from "@components/Skeleton/CrewEventDetailSkeleton";
import EventComments from "@components/Comments/signleCrewEventComments/EventComments";
import LocationBlueIcon from "@assets/Bluelocation.svg"
import Flag from "@assets/Group 41725.svg"
import {
  CalanderIcon,
  ClockIcon,
  LocationIconLight,
  DefaultUserImg,
} from "@utils/defaultImages";
import {
  useCrewAttendeesQuery,
  useCrewSingleEventQuery,
  useLazyRespondTypeQuery,
} from "@redux/services/crewService/allCrewService";
import { useMediaQuery } from "react-responsive";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import useNavigateMap from "@utils/hooks/useNavigateMap";
import { Link } from "react-router-dom";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import ReadMore from "@components/ReadMore";

const CrewEventDetail = () => {
  const { openMap, generateGoogleMapsUrl } = useNavigateMap();

  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  // {GLOBALLY GET USER LOGIN IN DATA}
  const user = useSelector((state) => state?.auth?.loginUserData);
  const { postId } = useParams();
  /**
   * USESTATES
   */
  const [openCrewLikeModal, setOpenCrewLikeModal] = useState(false);
  //

  /**
   * API CALLS
   */
  const {
    data: { data: detailEventData } = [],
    isLoading,
    refetch: getBtnResult,
  } = useCrewSingleEventQuery(postId);
  // console.log(detailEventData);
  const { data: { data } = {}, refetch } = useCrewAttendeesQuery(postId);
  const [respondType, { isLoading: loadBtn, isFetching }] =
    useLazyRespondTypeQuery();
  //

  /**
   * FUNCTIONS
   */
  const buttonRespondType = async (eventId, type) => {
    const values = {
      eventId: eventId,
      type: type,
    };
    try {
      const buttonType = await respondType(values).unwrap();
      if (buttonType?.code === 200) {
        getBtnResult();
        refetch();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  //
  // console.log("detailEventData", detailEventData);
  return (
    <>
      <div className="banner-header">
        {isLoading ? (
          isMobile || isTablet ? (
            <SpinnerLoader />
          ) : (
            <CrewEventDetailSkeleton />
          )
        ) : (
          <>
            <div className="wrap-banner">
              {detailEventData?.location && (
                <Link
                  target="_blank"
                  to={openMap}
                  onClick={() =>
                    generateGoogleMapsUrl(detailEventData?.location)
                  }
                >
                  <GoogleMapComponent detailEventData={detailEventData} />
                </Link>
              )}
              <div className="section">
                <div className="wrapSection">
                  <div className="weekend">
                    <div className="meetup">
                      <h4>{detailEventData?.title}</h4>
                      <p>Recurring Weekly</p>
                      <div className="showInfo">
                        <div className="locationInfo">
                          <div className="clm">
                            <div className="icon">
                              <CalanderIcon />
                            </div>
                            <span>
                              {" "}
                              {StartAndEndDate(
                                detailEventData?.startDateTime,
                                detailEventData?.endDateTime
                              )}
                            </span>
                          </div>
                          <div className="clm">
                            <div className="icon">
                              <ClockIcon />
                            </div>
                            <span>
                              {formatTimeRange(
                                detailEventData?.startDateTime,
                                detailEventData?.endDateTime
                              )}
                            </span>
                          </div>
                          <div className="clm">
                            <div className="icon">
                              <LocationIconLight />
                            </div>
                            {detailEventData?.location?.length > 1 ? (
                              // <div class="dropdown">
                              //   <div className="multi-location position-relative">
                              //     <button
                              //       class="btn btn-secondary dropdown-toggle"
                              //       type="button"
                              //       data-bs-toggle="dropdown"
                              //       aria-expanded="false"
                              //     >
                              //       {sliceStringAtWord(
                              //         detailEventData?.location[0]?.name,
                              //         20
                              //       )}
                              //     </button>
                              //     <div className="position-absolute top-0 m-2">
                              //       <img src={MapImg} alt="MapImg" />
                              //     </div>
                              //     <ul class="dropdown-menu dropdown-menu-dark w-100">
                              //       {detailEventData?.location?.map(
                              //         (item, index) => (
                              //           <li key={index}>
                              //             <a
                              //               class="dropdown-item"
                              //               href={openMap}
                              //               onClick={() =>
                              //                 generateGoogleMapsUrl(
                              //                   detailEventData?.location
                              //                 )
                              //               }
                              //               target="_blank"
                              //             >
                              //               {sliceStringAtWord(item?.name, 20)}
                              //             </a>
                              //           </li>
                              //         )
                              //       )}
                              //     </ul>
                              //   </div>
                              // </div>
                              <div
                                class="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                {


                                  <div class="accordion-item">
                                    <h2
                                      class="accordion-header"
                                      id="flush-headingOne"
                                    >
                                      <img src={MapImg} alt="image" />

                                      <p>
                                        {detailEventData?.location[0].name.length > 25 ? `${detailEventData?.location[0].name.slice(0, 25)}...` :
                                          detailEventData?.location[0].name}
                                      </p>
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne"
                                      >

                                      </button>
                                    </h2>
                                    {
                                      detailEventData.location.map((item, index) => {
                                        return (
                                          <div
                                            id="flush-collapseOne"
                                            class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne"
                                            data-bs-parent="#accordionFlushExample"
                                          >
                                            <div class="accordion-body">
                                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                {
                                                  index !== 0 &&
                                                  <div style={{ color: "#387AFF", textAlign: "center" }}>
                                                    |
                                                  </div>
                                                }
                                                {
                                                  index === 0 ?
                                                    <img className="imageIcon" src={LocationBlueIcon} alt="image" /> :
                                                    index === detailEventData.location.length - 1 ?
                                                      <img className="imageIcon" src={Flag} alt="image" /> :
                                                      <p className="imageIcon">{index}</p>
                                                }


                                              </div>


                                              <p>
                                                {item.name.length > 32 ? `${item.name.slice(0, 32)}...` :
                                                  item.name}
                                              </p>
                                            </div>

                                          </div>
                                        )
                                      })
                                    }

                                  </div>
                                }
                              </div>
                            ) : (
                              <span>
                                {detailEventData?.location?.name ||
                                  detailEventData?.location[0]?.name}
                              </span>
                            )}
                          </div>
                        </div>
                        {detailEventData?.description?.length > 0 && (
                          <div className="description">
                            <p>{detailEventData?.description}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="month">
                      <div className="event-month">
                        <p>
                          {" "}
                          {getOnlyMonth(
                            detailEventData?.startDateTime
                          ).substring(0, 3)}
                        </p>
                        <h3>
                          <strong>
                            {getOnlyDate(detailEventData?.startDateTime)}
                          </strong>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="last-section">
                    {user?._id !== detailEventData?.user?._id &&
                      detailEventData?.userJoinStatus !== 4 && (
                        <div
                          className="button-check"
                          onClick={() =>
                            buttonRespondType(
                              postId,
                              !detailEventData?.isAttend ? 1 : 2
                            )
                          }
                        >
                          <button
                            style={{
                              backgroundColor: !detailEventData?.isAttend
                                ? "#387AFF"
                                : "#323334",
                              border: !detailEventData?.isAttend
                                ? "2px solid transparent"
                                : "2px solid #FFFFFF",
                            }}
                            disabled={loadBtn || isFetching}
                          >
                            {loadBtn || isFetching ? (
                              <ButtonLoader />
                            ) : !detailEventData?.isAttend ? (
                              "I’ll be there"
                            ) : (
                              "I can’t make it"
                            )}
                          </button>
                        </div>
                      )}
                    {data?.docs?.length > 0 && (
                      <div
                        className="attending"
                      // onClick={() => setOpenCrewLikeModal(!openCrewLikeModal)}
                      >
                        <p>{data?.docs?.length} Attending</p>
                        <div className="attending-avatar">
                          {data?.docs?.map(({ profileImage, username }) => (
                            <img
                              src={
                                isValidUrl(profileImage)
                                  ? profileImage
                                  : DefaultUserImg
                              }
                              alt={username}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="" style={{ padding: "16% 3% 5%" }}>
              <EventComments
                post={detailEventData}
                postId={detailEventData?._id}

              isTablet={isTablet}
              />
            </div>
          </>
        )}
      </div>
      {/* <div id="modal">
        {openCrewLikeModal && (
          <WrapperModal
            open={openCrewLikeModal}
            closeModal={() => {
              setOpenCrewLikeModal(false);
            }}
            style={{
              top: "50%",
              transform: "translateY(-50%)",
              left: 0,
              right: 0,
              margin: "0 auto",
              backgroundColor: "#121313",
            }}
          >
            <div>
              <AttendingListModal
                // likeResult={likeResult}
                // loading={loading}
                allocateCrewId={postId}
              />
            </div>
          </WrapperModal>
        )}
      </div> */}
    </>
  );
};

export default CrewEventDetail;
