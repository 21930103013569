  import React from "react";
  import "./MultipleUsers.scss";
  import { isValidUrl } from "@utils/validUrl";
  import { DefaultUserImg } from "@utils/defaultImages";

  export const MultipleUsers = ({ property1, crewMembers }) => {
    return (
      <>
        {[1, 2, 3, 4,5,6].map(({ profileImage }, index) => {
          // console.log("index", index);
          return (
            <div
              className={`multiple-users ${property1}`}
              style={{
                overflow: "hidden",
                // marginLeft: crewMembers?.length > 1 ? "-17px" : "",
                marginLeft: crewMembers?.length > 1 ? "-17px" : "-8px",
              }}
            >
              <img
                className="avatar"
                alt="Avatar"
                src={isValidUrl(profileImage) ? DefaultUserImg : DefaultUserImg}
              />
            </div>
          );
        })}
      </>
    );
  };


