import React, { useState } from "react";
import BannerImg from "@assets/userProfileImgs/default.png";
import "./CrewPost.scss";
import { useParams } from "react-router";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import USerProfileSkeleton from "@components/Skeleton/USerProfileSkeleton";
import CrewProfile from "./components/CrewProfile";
import Posts from "./components/Posts";
import Events from "./components/Events";
import About from "./components/About";
import UpcomingEvents from "./components/UpcomingEvents";
import Tabs from "./components/Tabs";
import { useSelector } from "react-redux";
import { useGetCrewByIdQuery } from "@redux/services/crewService/allCrewService";
import { useMediaQuery } from "react-responsive";
export default function CrewPost() {
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  const { crewId } = useParams();
  /**
   * GET DATA LOCALLY FROM STORAGE(REDUX PERSIST)
   */
  const loginUserInfo = useSelector((state) => state?.auth?.loginUserData);
  /**
   * USESTATES
   */
  const [tabOpen, setTabOpen] = useState(1);

  /**
   * API CALL
   */
  const { data: { data: { crew } = {} } = {}, isLoading } =
    useGetCrewByIdQuery(crewId);
  //

  return (
    <>
      <div>
        {isLoading ? (
          isMobile || isTablet ? (
            <SpinnerLoader />
          ) : (
            <div className="user-profile-section">
              <img src={BannerImg} className="banner-img" />
              <div className="container">
                <div className="user-data">
                  <USerProfileSkeleton />
                </div>
              </div>
            </div>
          )
        ) : (
          <CrewProfile crewData={crew} user={loginUserInfo} />
        )}
        <div className="crew-post-wrapper">
          <div className="p-3">
            <Tabs tabOpen={tabOpen} setTabOpen={setTabOpen} />
            {tabOpen === 1 && (
              <Events
                crewData={crew}
                user={loginUserInfo}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
            {tabOpen === 2 && (
              <Posts
                crewData={crew}
                user={loginUserInfo}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
            {tabOpen === 3 && (
              <About
                crewData={crew}
                user={loginUserInfo}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
            {/* {tabOpen === 4 && <Saved />} */}
          </div>
          {!(isMobile || isTablet) && (
            <UpcomingEvents crewData={crew} user={loginUserInfo} />
          )}
        </div>
      </div>
    </>
  );
}
