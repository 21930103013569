import React from "react";
import "./ProfileHeader.scss";
import { BackArrow, ThreeDots } from "@utils/defaultImages";
import { useNavigate } from "react-router";

export default function ProfileHeader({ firstName, lastName }) {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="head">
        <div className="back-button" onClick={() => navigate(-1)}>
          <BackArrow />
        </div>{" "}
        <h1>
          {firstName} {lastName}
        </h1>
        <div className="dott">
          <ThreeDots />
        </div>
      </div>
    </div>
  );
}
