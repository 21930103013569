import React, {  useState } from "react";
import SiteLogo from "@assets/site-logo.svg";
import "./Signup.scss";
import { ReactComponent as Back } from "@assets/back.svg";
import { ReactComponent as Skip } from "@assets/Skip.svg";
import { ReactComponent as CameraIcon } from "@assets/CameraIcon.svg";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, useNavigation } from "react-router";
import {
  setBioDescGen,
  setGenSelectedFile,
} from "@redux/slices/authSlice/signUpSlice";

const YourProfile = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = location?.state?.user;

  let selectedFile, bioDesc;
  const handleFileChange = (e) => {
    // console.log('object', e.target.value)
    const file = e?.target?.files?.[0];
    dispatch(setGenSelectedFile(file));
    // Check if a file is selected
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div className="signup-container">
        <div className="signup-wrapper">
          <img src={SiteLogo} alt="" className="logo" />
          <div className="lets-go">Let’s set up your profile.</div>
          <div className="form-group">
            <label className="upload-file-input">
              <input type="file" onChange={handleFileChange} />
              <div className="icon">
                {!selectedFile ? (
                  <CameraIcon />
                ) : (
                  <img src={imagePreview} alt="selected" className="icon" />
                )}
              </div>
              <span>Upload a profile picture</span>
            </label>
          </div>

          <div className="space10"></div>

          <div className="form-group">
            <textarea
              className="form-control text-area"
              placeholder="Tell us about you..."
              onChange={(e) => dispatch(setBioDescGen(e.target.value))}
            ></textarea>
          </div>

          <button
            className="next-btn"
            onClick={() =>
              navigate("/popular-accounts", {
                state: {
                  user: { ...userInfo },
                },
              })
            }
          >
            Next
          </button>
          <div className="ft-buttons">
            <div className="back-btn" onClick={() => navigate("/general-info")}>
              <Back />
              Back
            </div>
            <div>
              <div
                className="back-btn"
                onClick={() => navigate("/popular-accounts")}
              >
                Skip
                <Skip />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default YourProfile;
