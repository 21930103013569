import React from "react";
import CheckBox from "@components/Checkbox/CheckBox";

const ExploreFilters = ({ activeCategory, userinfo, setUserInfo }) => {
  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { years } = userinfo;

    // console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        years: [...years, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        years: years.filter((e) => e !== value),
      });
    }
  };
  return (
    <>
      {(activeCategory == "Builds" || activeCategory == "Crews") && (

            <div className="search-filters">
              <h3>Sort by</h3>

              <div id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="acc-btn collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Featured
                      <i className="fas fa-chevron-down float-end"></i>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body">Featured</div>
                  </div>
                </div>
                {activeCategory == "Builds" ? (
                  <>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Year
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordion"
                      >
                        <div className="accordion-body">
                          <div id="accordion-sub">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne1">
                                <button
                                  className="acc-btn collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne1"
                                  aria-expanded="true"
                                  aria-controls="collapseOne1"
                                >
                                  2010's
                                  <i className="fas fa-chevron-down float-end"></i>
                                </button>
                              </h2>
                              <div
                                id="collapseOne1"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne1"
                                data-bs-parent="#accordion-sub"
                              >
                                <div className="accordion-body">
                                  <label className="make-checkbox">
                                    <CheckBox
                                      type="checkbox"
                                      name="years"
                                      value="2019"
                                      onChange={handleChange}
                                      className="fa-solid fa-check"
                                    />
                                    2019
                                  </label>
                                  <label className="make-checkbox">
                                    <CheckBox
                                      type="checkbox"
                                      name="years"
                                      value="2020"
                                      onChange={handleChange}
                                      className="fa-solid fa-check"
                                    />
                                    2020
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Add more second-level accordion items here */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Add more top-level accordion items here */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          Make
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        {/* <div className="accordion-body">Featured</div> */}
                        <div className="accordion-body">
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            Ford
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            Audi
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Add models of car */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          Model
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        {/* <div className="accordion-body">Featured</div> */}
                        <div className="accordion-body">
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2005
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2006
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Add Trim Data */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="true"
                          aria-controls="collapseFive"
                        >
                          Trim
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        {/* <div className="accordion-body">Featured</div> */}
                        <div className="accordion-body">
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2005
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2006
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Add Engine type */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          Engine
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        {/* <div className="accordion-body">Featured</div> */}
                        <div className="accordion-body">
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2005
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2006
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Drive type */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="true"
                          aria-controls="collapseSeven"
                        >
                          Drive Type
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        {/* <div className="accordion-body">Featured</div> */}
                        <div className="accordion-body">
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2005
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2006
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Transmission */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="true"
                          aria-controls="collapseEight"
                        >
                          Transmission
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        {/* <div className="accordion-body">Featured</div> */}
                        <div className="accordion-body">
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2005
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                          <label className="make-checkbox">
                            <input type="checkbox" name="make"></input>
                            2006
                            <span>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : activeCategory == "Crews" ? (
                  <>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="acc-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Categories
                          <i className="fas fa-chevron-down float-end"></i>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordion"
                      >
                        <div className="accordion-body">
                          <div id="accordion-sub">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne1">
                                <button
                                  className="acc-btn collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne1"
                                  aria-expanded="true"
                                  aria-controls="collapseOne1"
                                >
                                  2010's
                                  <i className="fas fa-chevron-down float-end"></i>
                                </button>
                              </h2>
                              <div
                                id="collapseOne1"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne1"
                                data-bs-parent="#accordion-sub"
                              >
                                <div className="accordion-body">
                                  <label className="make-checkbox">
                                    <CheckBox
                                      type="checkbox"
                                      name="years"
                                      value="2019"
                                      onChange={handleChange}
                                      className="fa-solid fa-check"
                                    />
                                    2019
                                  </label>
                                  <label className="make-checkbox">
                                    <CheckBox
                                      type="checkbox"
                                      name="years"
                                      value="2020"
                                      onChange={handleChange}
                                      className="fa-solid fa-check"
                                    />
                                    2020
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Add more second-level accordion items here */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
      
      )}
      </>
  );
};

export default ExploreFilters;
