import React, { useEffect, useState } from "react";
import SiteLogo from "@assets/site-logo.svg";
import "./Signup.scss";
import { ReactComponent as Back } from "@assets/back.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {  useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralInfo } from "@redux/slices/authSlice/signUpSlice";
const google_maps_key = process.env.REACT_APP_YOUR_GOOGLE_MAPS_API_KEY || "";
const GeneralInfo = () => {
  const [validationErrors, setValidationErrors] = useState({
    gender: "",
    birthday: "",
    location: "",
  });
  const validateForm = () => {
    const errors = {
      gender: "",
      birthday: "",
      location: "",
    };

    if (!showValues.gender) {
      errors.gender = "Please select your gender.";
    }

    if (!startDate) {
      errors.birthday = "Please select your birthday.";
    }

    if (!selectedLocation) {
      errors.location = "Please select your location.";
    }

    setValidationErrors(errors);

    return Object.values(errors).every((error) => error === "");
  };
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const generateYears = () => {
    const startYear = 1990;
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = startYear; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  };

  const years = generateYears();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [showValues, setShowValues] = useState({
    gender: "",
    dateOfBirth: startDate,
    location: {
      name: selectedLocation?.formatted_address,
      latitude: selectedLocation?.geometry.location.lat(),
      longitude: selectedLocation?.geometry.location.lng(),
      latitudeDelta: 0.0922,
      longitudeDelta: 0.0421,
    },
  });
  useEffect(() => {
    setShowValues((prevValues) => ({
      ...prevValues,
      dateOfBirth: startDate,
      location: {
        name: selectedLocation?.formatted_address,
        latitude: selectedLocation?.geometry.location.lat(),
        longitude: selectedLocation?.geometry.location.lng(),
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      },
    }));
  }, [selectedLocation, startDate]);
  useEffect(() => {
    dispatch(setGeneralInfo(showValues));
  }, [dispatch, showValues]);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "radio" && checked) {
      setShowValues({
        ...showValues,
        gender: value,
      });
    } else {
      setShowValues({
        ...showValues,
        [name]: value,
      });
    }
  };
  const handlePlaceSelected = (place) => {
    setSelectedLocation(place);
  };
  const handleNextClick = () => {
    if (validateForm()) {
      navigate("/user-profile");
    }
  };
  return (
    <div>
      <div className="signup-container">
        <div className="signup-wrapper">
          <img src={SiteLogo} alt="" className="logo" />
          <div className="lets-go">Welcome, FranicGlimpie.</div>
          <div className="enter-text">Let’s get to know the real you.</div>
          {/* <div className="form-group">
            <label>What’s your name?</label>
            <div className="two-form">
              <input
                type="text"
                name="firstName"
                className="form-control"
                placeholder="First name"
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Last name"
                onChange={handleInputChange}
              />
            </div>
          </div> */}

          <div className="space10"></div>

          <div className="form-group">
            <label>What’s your gender?</label>
            <div className="three-form">
              {["Male", "Female", "Custom"].map((option) => (
                <label key={option}>
                  <input
                    type="radio"
                    name="gender"
                    value={option}
                    checked={showValues.gender === option}
                    onChange={handleInputChange}
                  />
                  <span>{option}</span>
                </label>
              ))}
            </div>
          </div>
          {validationErrors.gender && (
            <div className="validation-error">{validationErrors.gender}</div>
          )}

          <div className="space10"></div>

          <div className="form-group">
            <label>When is your birthday?</label>

            <div className="three-form">
              {/* <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showYearDropdown={true}
                dateFormat="yyyy/MM/d " // Change the format here
                className="form-control fld"
                placeholderText="Select a date"
              /> */}

              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#121313",
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                      style={{
                        border: "none",
                        background: "#000",
                        color: "#fff",
                        marginRight: "2px",
                        padding: "4px",
                      }}
                    >
                      {"<"}
                    </button>
                    <select
                      style={{
                        background: "#121313",
                        border: "1px solid #000",
                        color: "#fff",
                        marginRight: "2px",
                      }}
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      style={{
                        background: "#121313",
                        border: "1px solid #000",
                        color: "#fff",
                        marginRight: "2px",
                      }}
                      value={months[date.getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                      style={{
                        border: "none",
                        background: "#121313",
                        color: "#fff",
                        marginRight: "2px",
                        padding: "4px",
                      }}
                    >
                      {">"}
                    </button>
                  </div>
                )}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy/MM/d " // Change the format here
                className="form-control fld"
                placeholderText="Select a date"
              />

              {/* <div>
                <div className="in-label">Month</div>
                <input
                  type="text"
                  className="form-control fld"
                  placeholder="September"
                />
              </div>
              <div>
                <div className="in-label">Day</div>
                <input
                  type="text"
                  className="form-control fld"
                  placeholder="6"
                />
              </div>
              <div>
                <div className="in-label">Year</div>
                <input
                  type="text"
                  className="form-control fld"
                  placeholder="2020"
                />
              </div> */}
            </div>
          </div>
          {validationErrors.birthday && (
            <div className="validation-error">{validationErrors.birthday}</div>
          )}

          <div className="space10"></div>
          <div className="form-group">
            <label>Where are you located?</label>
            {/* <input
              type="text"
              className="form-control"
              placeholder="Location"
            /> */}
            <Autocomplete
              apiKey={google_maps_key}
              onPlaceSelected={handlePlaceSelected}
              className="form-control"
              placeholder="Location"
            />
          </div>
          {validationErrors.location && (
            <div className="validation-error" style={{ marginBottom: "15px" }}>
              {validationErrors.location}
            </div>
          )}
          <button className="next-btn" onClick={handleNextClick}>
            Next
          </button>
          <div className="back-btn">
            <Back />
            Back
          </div>
        </div>
      </div>
    </div>
  );
};
export default GeneralInfo;
