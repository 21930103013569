import React, { useCallback, useEffect, useState } from "react";
import { LocationIcon, DefaultImg } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { Link } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import _debounce from "lodash/debounce";
import { useLazyGetAllNearCrewQuery } from "@redux/services/crewService/allCrewService";
import NearYouRecomendedSkeleton from "@components/Skeleton/NearYouRecomendedSkeleton";
import NotFound from "@components/NotFound";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const AllNearCrews = ({ isMobile, isTablet }) => {
  /**
   * API CALLS
   */
  const [getAllNearCrew, { data: apiData, isLoading, isFetching }] =
    useLazyGetAllNearCrewQuery();

  const data = apiData?.data;

  /**
   * USESTATES
   */
  const [searchValues, setSearchValues] = useState("");
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  /**
   * FUNCTIONS
   */
  const nearByResult = useCallback(
    async (values) => {
      const value = {
        page: page,
        searchBy: values,
      };
      try {
        const res = await getAllNearCrew(value).unwrap();
        setItems((prevItems) =>
          page === 1 ? res?.data?.docs : [...prevItems, ...res?.data?.docs]
        );
      } catch (error) {
        console.log("error", error);
      }
    },
    [getAllNearCrew, page]
  );

  const debouncedHandleSearch = useCallback(
    _debounce((inputValue) => nearByResult(inputValue), 1000),
    [nearByResult]
  );

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setSearchValues(inputValue);
    setPage(1);
    debouncedHandleSearch(inputValue);
  };

  const loadMore = useCallback(() => {
    if (!isFetching && data?.nextPage) {
      setPage(data?.nextPage);
    }
  }, [isFetching, data?.nextPage]);

  /**
   * USEEFFECT
   */
  useEffect(() => {
    nearByResult(searchValues);
  }, [page, nearByResult]);

  return (
    <>
      {/* search */}
      <div className="full-search-crew">
        <div className="search-form">
          <div className="search-fld"> 
            <input
              value={searchValues}
              type="search"
              className="form-control"
              placeholder="Search for Carvonix communities & crews"
              onChange={handleChange}
            />
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                {/* ... (button SVG path) */}
                <path
                  d="M12.0094 12.5102L16.666 17.1667"
                  stroke="#F5F5F5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                  stroke="#F5F5F5"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* {show location} */}
      <div className="view-all-heading">
        <h2>
          Near you
          {/* <span className="location" style={{ fontSize: "17px" }}>
            <LocationIcon
              style={{
                stroke: "#ffffff",
                width: "16px",
                height: "16px",
              }}
            />{" "}
            {name}
          </span> */}
        </h2>
      </div>
      {/* {show result of API} */}
      {isLoading ? (
        isLoading && (isMobile || isTablet) ? (
          <SpinnerLoader />
        ) : (
          Array.from({ length: 3 }).map((_, index) => (
            <NearYouRecomendedSkeleton key={index} />
          ))
        )
      ) : data?.docs?.length === 0 ? (
        <NotFound message="Crews" />
      ) : (
        <>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={items?.length}
            next={loadMore}
            hasMore={!!data?.nextPage}
            loader={<SpinnerLoader />}
            // endMessage={<p style={{ textAlign: "center" }}>No more results</p>}
          >
            <div className="crew-garage-row">
              {items?.map((item) => (
                <Link
                  to={`/crew-post/${item?._id}`}
                  style={{ color: "#FFF" }}
                  key={item?._id}
                >
                  <div
                    className="garage-block"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={
                        isValidUrl(item?.bannerImage)
                          ? item?.bannerImage
                          : DefaultImg
                      }
                      alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
                    />
                    <div className="info">
                      <div className="model">
                        {sliceStringAtWord(item?.name, 10)}
                      </div>
                      {/* <div className="name">MeetUp</div> */}
                    </div>
                    {/* <div
                      className="group-2"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "28px",
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="text-wrapper-3"
                        style={{ fontSize: "14px", fontWeight: 400 }}
                      >
                        {item?.memberCount} members
                      </div>
                      <MultipleUsers
                        crewMembers={item?.crewMembersData}
                        className="multiple-users-instance"
                        property1="users-sm"
                      />
                    </div> */}
                  </div>
                </Link>
              ))}
            </div>
          </InfiniteScroll>
        </>
      )}
    </>
  );
};

export default AllNearCrews;
