import React from "react";
import UpComingCrewEvents from "./components/UpComingCrewEvents";
import CrewNearYou from "./components/CrewNearYou";
import UsersCrew from "./components/UsersCrew";
import { useMediaQuery } from "react-responsive";

const CrewFreed = () => {
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  return (
    <>
      <div className="px-3">
        {!(isMobile) && (
          <UsersCrew isMobile={isMobile} isTablet={isTablet} />
        )}
        <CrewNearYou isMobile={isMobile} isTablet={isTablet} />
      </div>
      {!(isMobile || isTablet) && (
        <div>
          <UpComingCrewEvents />
        </div>
      )}
    </>
  );
};

export default CrewFreed;
